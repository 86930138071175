.movie__list {
  padding: 0 3rem 3rem 3rem;
}

.list__title {
  font-size: 1.75rem;
  margin: 2.5rem;
}

.list__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
