* {
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  min-height: 100%;
}
