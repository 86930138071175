.header {
  margin: 0 2.5rem;
  padding: 0.5rem 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.headerLeft,
.headerRight {
  display: flex;
  align-items: center;
}

a > span {
  margin: 0 30px;
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
}

a > span:hover {
  color: red;
}

.header__icon {
  width: 80px;
  cursor: pointer;
}

.user__avatar {
  font-size: 2rem;
}
